import { useState } from 'react';
import { containerStyle, headerStyle } from "../../styles/common";
import WalletSDK from "../../utils/wallet";

function Home() {
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [isWalletConnected, setIsWalletConnected] = useState(false);

    const paragraphStyle = {
        textAlign: 'justify' as 'justify',
        marginBottom: '20px'
    };

    const offerHeaderStyle = {
        fontWeight: 'bold',
        fontSize: '1.2em',
        marginBottom: '10px'
    };

    const offerListStyle = {
        listStyleType: 'disc',
        marginLeft: '1rem',
        marginBottom: '20px'
    };

    async function connectWallet() {
        const walletSDK = new WalletSDK();
        try {
            const accounts = await walletSDK.connectWallet();
            console.log("Connected accounts:", accounts);
            setWalletAddress(accounts[0]); // assuming accounts[0] is the wallet address
            setIsWalletConnected(true);
        } catch (error) {
            console.error("Error:", error);
        }
    }


    return (
        <div style={containerStyle}>
            <h2 style={headerStyle}>Welcome to Web3 Dream Come True! </h2>
            {/* {isWalletConnected ? (
                <p>Connected wallet: {walletAddress}</p>
            ) : (
                <button onClick={connectWallet}>Connect Wallet</button>
            )} */}
            <p style={paragraphStyle}>
                Navigating the complex landscape of EVM-compatible blockchains can be a daunting task, whether you're a seasoned developer or a project owner looking to make your mark in the decentralized world. That's where "Web3 Dream Come True" comes in. Our mission is simple: to make your journey through the Web3 ecosystem as seamless and intuitive as possible.
            </p>
            <p style={offerHeaderStyle}>What We Offer</p>
            <ul style={offerListStyle}>
                <li>Contract State Inspector: Ever wondered what's happening inside a smart contract? Our state-of-the-art Contract State Inspector allows you to query contract states with ease. Just input the ABI, blockchain network or RPC endpoint, and contract address, and you're good to go!</li>
                <li>Event Log Decoder: Stay in the loop with real-time event tracking. Our Event Log Decoder lets you monitor smart contract events by simply entering the ABI, blockchain network or RPC endpoint, and contract address.</li>
            </ul>
            <p style={paragraphStyle}>
                Whether you're looking to decode event logs, query contract states, or simply understand the intricacies of EVM-compatible blockchains, "Web3 Dream Come True" is your one-stop solution.
            </p>
            <p style={paragraphStyle}>
                So go ahead, explore the site, and make your Web3 dreams come true!
            </p>
        </div>
    );
}

export default Home;
