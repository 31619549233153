import { Routes, Route, Outlet, Link } from "react-router-dom";
import ContractStateInspector from './pages/ContractStateInspector';
import EventLogDecoder from './pages/EventLogDecoder';
import Home from "./pages/Home";
import Logo from './images/logo.png';

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contract-state-inspector" element={<ContractStateInspector />} />
          <Route path="event-log-decoder" element={<EventLogDecoder />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top navbar-dark navbarScroll">
        <div className="navbar-logo">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contract-state-inspector">Contract State Inspector</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/event-log-decoder">Event Log Decoder</Link>
            </li>
          </ul>
        </div>
      </nav>
      <hr />
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
