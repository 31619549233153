const sha3 = require("./sha3");

function encodeInputData(inputs: any[], params: any[]) {
  let encodedData = "";
  let dynamicInputs = [];
  let dynamicParams = [];
  for (let i = 0; i < params.length; i++) {
    const param = params[i];
    const input = inputs[i];
    if (input.type === "address") {
      encodedData += param.replace("0x", "").padStart(64, "0");
    } else if (input.type === "uint256") {
      encodedData += BigInt(param).toString(16).padStart(64, "0");
    } else if (input.type === "bytes32") {
      encodedData += param.replace("0x", "");
    } else if (input.type === "bool") {
      encodedData += param.padStart(64, "0");
    } else if (input.type === "tuple[]") {
      encodedData += BigInt((inputs.length - i) * 32)
        .toString(16)
        .padStart(64, "0");
      dynamicInputs.push(input);
      dynamicParams.push(param);
    } else if (input.type === "bytes") {
      const txData = param.replace("0x", "");
      encodedData += BigInt(64).toString(16).padStart(64, "0");
      encodedData += BigInt(txData.length / 2)
        .toString(16)
        .padStart(64, "0");
      encodedData += txData + "0".repeat(64 - (txData.length % 64));
    }
  }

  for (let i = 0; i < dynamicParams.length; i++) {
    let dynamicParam = dynamicParams[i];
    let input = dynamicInputs[i];
    if (input.type === "tuple[]") {
      let components = input.components;
      encodedData += BigInt(dynamicParam.length).toString(16).padStart(64, "0");

      let componentsEncodedDataArr = [];
      let componentsEncodedDataTotalLength = 0;
      for (let j = 0; j < dynamicParam.length; j++) {
        const componentsEncodedData = encodeInputData(
          components,
          dynamicParam[j]
        );
        componentsEncodedDataArr.push(componentsEncodedData);
        if (j <= dynamicParam.length - 1) {
          encodedData += BigInt(
            dynamicParam.length * 32 + componentsEncodedDataTotalLength
          )
            .toString(16)
            .padStart(64, "0");
        }
        componentsEncodedDataTotalLength += componentsEncodedData.length / 2;
      }

      encodedData += componentsEncodedDataArr.join("");
    }
  }
  return encodedData;
}

function isHumanReadable(str: string) {
  // Check if all characters are printable ASCII characters
  return /^[\x20-\x7E]+$/.test(str);
}

function bytes32ToString(bytes32Hex: string) {
  if (bytes32Hex.startsWith("0x")) {
    bytes32Hex = bytes32Hex.slice(2);
  }

  let utf8String: string;

  if (typeof window !== "undefined") {
    var bin = hex2bin(bytes32Hex);
    utf8String = new TextDecoder().decode(
      new Uint8Array(
        bin.split("").map(function (c) {
          return c.charCodeAt(0);
        })
      )
    );
  } else {
    const buffer = Buffer.from(bytes32Hex, "hex");
    utf8String = buffer.toString("utf-8").replace(/\u0000/g, "");
  }

  if (isHumanReadable(utf8String)) {
    return utf8String;
  } else {
    return "0x" + bytes32Hex;
  }
}

function hex2bin(hex: string) {
  var bytes = [],
    str;
  for (var i = 0; i < hex.length - 1; i += 2)
    bytes.push(parseInt(hex.substr(i, 2), 16));
  return String.fromCharCode.apply(String, bytes);
}

function decodeOutputData(abiItem: any, rawData: string) {
  const data = rawData.replace("0x", "");
  let index = 0;
  let outputObj: any = {};
  let outputs = abiItem.outputs;

  const hexToBigInt = (hex: string) => BigInt(hex);
  const bigIntToNumber = (bi: bigint) => Number(bi);
  const hexToDecimalString = (hex: string) => hexToBigInt(hex).toString();

  for (let output of outputs) {
    if (output.type.endsWith("[]")) {
      let fieldIndex = bigIntToNumber(
        hexToBigInt("0x" + data.slice(index, index + 64)) * 2n
      );
      const arrayItemsCount = bigIntToNumber(
        hexToBigInt("0x" + data.slice(fieldIndex, fieldIndex + 64))
      );
      fieldIndex += 64;
      let itemPointerPositionStart = fieldIndex;
      let arr = [];
      for (let i = 0; i < arrayItemsCount; i++) {
        if (output.type === "address[]") {
          arr.push(
            "0x" +
              data
                .slice(fieldIndex, fieldIndex + 64)
                .replace("000000000000000000000000", "")
          );
        } else if (output.type === "bool[]") {
          arr.push(
            hexToBigInt("0x" + data.slice(fieldIndex, fieldIndex + 64)) === 1n
          );
        } else if (output.type.startsWith("uint")) {
          arr.push(
            hexToDecimalString("0x" + data.slice(fieldIndex, fieldIndex + 64))
          );
        } else if (output.type === "bytes32[]") {
          let value = bytes32ToString(
            "0x" + data.slice(fieldIndex, fieldIndex + 64)
          );
          arr.push(value);
        } else if (output.type === "bytes[]") {
          let itemPointerPosition = itemPointerPositionStart + 64 * i;
          let itemPosition = bigIntToNumber(
            hexToBigInt(
              "0x" + data.slice(itemPointerPosition, itemPointerPosition + 64)
            ) * 2n
          );
          let itemBytesLength = bigIntToNumber(
            hexToBigInt(
              "0x" +
                data.slice(
                  itemPointerPositionStart + itemPosition,
                  itemPointerPositionStart + itemPosition + 64
                )
            )
          );
          let itemValue = data.substr(
            itemPointerPositionStart + itemPosition + 64,
            itemBytesLength * 2
          );
          arr.push(itemValue);
        }
        fieldIndex += 64;
      }
      outputObj[output.name] = arr;
      index += 64;
    } else if (output.type.endsWith("]")) {
      let arr = [];
      let pattern = RegExp(/\[([0-9]+)\]/);
      let matches = output.type.match(pattern);
      if (matches && matches.length > 0) {
        let itemsCount = Number(matches[0].replace(pattern, "$1"));
        for (let i = 0; i < itemsCount; i++) {
          if (output.type.startsWith("address[")) {
            arr.push(
              "0x" +
                data
                  .slice(index + 64 * i, index + 64 * (i + 1))
                  .replace("000000000000000000000000", "")
            );
          } else if (output.type.startsWith("bool[")) {
            arr.push(
              hexToBigInt(
                "0x" + data.slice(index + 64 * i, index + 64 * (i + 1))
              ) === 1n
            );
          } else if (output.type.startsWith("uint")) {
            arr.push(
              hexToDecimalString(
                "0x" + data.slice(index + 64 * i, index + 64 * (i + 1))
              )
            );
          } else if (output.type.startsWith("bytes32[")) {
            let value = bytes32ToString(
              "0x" + data.slice(index + 64 * i, index + 64 * (i + 1))
            );
            arr.push(value);
          } else if (output.type.startsWith("string[")) {
            let fieldIndex = bigIntToNumber(
              hexToBigInt("0x" + data.slice(index, index + 64)) * 2n
            );
            const charactersCount = bigIntToNumber(
              hexToBigInt("0x" + data.slice(fieldIndex, fieldIndex + 64)) * 2n
            );
            fieldIndex += 64;
            let text = data.substr(fieldIndex, charactersCount);
            arr.push(bytes32ToString(text));
            index += 64;
          } else if (output.type.startsWith("tuple[")) {
            let components = output.components;
            let tuple = [];
            for (let j = 0; j < components.length; j++) {
              let component = components[j];
              if (component.type === "address") {
                tuple.push(
                  "0x" +
                    data
                      .slice(index, index + 64)
                      .replace("000000000000000000000000", "")
                );
              } else if (component.type === "bool") {
                tuple.push(
                  hexToBigInt("0x" + data.slice(index, index + 64)) === 1n
                );
              } else if (component.type.startsWith("uint")) {
                tuple.push(
                  hexToDecimalString("0x" + data.slice(index, index + 64))
                );
              } else if (component.type === "bytes32") {
                let value = bytes32ToString(
                  "0x" + data.slice(index, index + 64)
                );
                tuple.push(value);
              }
              index += 64;
            }
            arr.push(tuple.join(","));
          }
        }
        index += 64 * itemsCount;
      }
      outputObj[output.name] = arr;
    } else if (output.type === "address") {
      outputObj[output.name] =
        "0x" +
        data.slice(index, index + 64).replace("000000000000000000000000", "");
      index += 64;
    } else if (output.type.startsWith("uint")) {
      outputObj[output.name] = hexToDecimalString(
        "0x" + data.slice(index, index + 64)
      );
      index += 64;
    } else if (output.type === "bytes32") {
      let value = bytes32ToString("0x" + data.slice(index, index + 64));
      outputObj[output.name] = value;
      index += 64;
    } else if (output.type === "bool") {
      outputObj[output.name] =
        hexToBigInt("0x" + data.slice(index, index + 64)) === 1n;
      index += 64;
    } else if (output.type === "string") {
      let fieldIndex = bigIntToNumber(
        hexToBigInt("0x" + data.slice(index, index + 64)) * 2n
      );
      const charactersCount = bigIntToNumber(
        hexToBigInt("0x" + data.slice(fieldIndex, fieldIndex + 64)) * 2n
      );
      fieldIndex += 64;
      let text = data.substr(fieldIndex, charactersCount);
      outputObj[output.name] = bytes32ToString(text);
      index += 64;
    } else if (output.type === "tuple") {
      let components = output.components;
      let arr = [];
      for (let i = 0; i < components.length; i++) {
        let component = components[i];
        if (component.type === "address") {
          arr.push(
            "0x" +
              data
                .slice(index, index + 64)
                .replace("000000000000000000000000", "")
          );
        } else if (component.type === "bool") {
          arr.push(hexToBigInt("0x" + data.slice(index, index + 64)) === 1n);
        } else if (component.type.startsWith("uint")) {
          arr.push(hexToDecimalString("0x" + data.slice(index, index + 64)));
        } else if (component.type === "bytes32") {
          let value = bytes32ToString("0x" + data.slice(index, index + 64));
          arr.push(value);
        }
        index += 64;
      }
      outputObj[output.name] = arr.join(",");
    } else {
      console.log("unknown output.type", output.type);
    }
  }
  return outputObj;
}

async function ethCall(url: string, contractAddress: string, txData: string) {
  const data = {
    id: 1,
    jsonrpc: "2.0",
    method: "eth_call",
    params: [
      {
        to: contractAddress,
        data: txData,
      },
      "latest",
    ],
  };
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json.result;
}

function getKeccak256Hash(input: string) {
  return sha3.keccak256(input);
}

export { encodeInputData, decodeOutputData, ethCall, getKeccak256Hash, bytes32ToString };
