import React, { useState, useEffect } from 'react';
import './index.css';
import { containerStyle, headerStyle } from "../../styles/common";
import { bytes32ToString, decodeOutputData, encodeInputData, ethCall, getKeccak256Hash } from '../../utils/ethRawCipher';
import uniswapV3PoolABI from '../../abi/uniswapV3PoolABI.json';
import CopyHash from '../../components/CopyHash';

interface ABIInput {
    type: string;
    name: string;
    inputs?: { name: string; type: string, components?: any[] }[];
}

const Networks = [
    {
        name: 'Ethereum Mainnet',
        chainId: 1
    },
    {
        name: 'Binance Smart Chain',
        chainId: 56
    },
    {
        name: 'Binance Smart Chain Testnet',
        chainId: 97
    },
    {
        name: 'Avalanche Fuji Testnet',
        chainId: 43113
    },
    {
        name: 'Avalanche Mainnet',
        chainId: 43114
    },
    {
        name: 'Polygon',
        chainId: 137
    }
];

function EventLogDecoder() {
    const [selectedCommonContracts, setSelectedCommonContracts] = useState<string>('UniswapV3Pool');
    const [useCustomAbiCheckbox, setUseCustomAbiCheckbox] = useState<boolean>(false);
    const [abiInput, setAbiInput] = useState<string>(JSON.stringify(uniswapV3PoolABI, null, 4));
    const [abi, setAbi] = useState<ABIInput[]>([]);
    const [contractAddressInput, setContractAddressInput] = useState('');
    const [eventsInfoMap, setEventsInfoMap] = useState<Record<string, any>>({});
    const [selectedChainId, setSelectedChainId] = useState<number>(137);
    const [outputData, setOutputData] = useState<any[]>([]);
    const [resultsCount, setResultsCount] = useState<number>(0);
    const [selectedAPIProvider, setSelectedAPIProvider] = useState<string>('ankr');
    const [apiKeyInput, setAPIKeyInput] = useState<string>('');
    const [selectedEventHash, setSelectedEventHash] = useState<string>('');
    const [prevMoralisAPICursor, setPrevMoralisAPICursor] = useState<string>('');
    const [nextMoralisAPICursor, setNextMoralisAPICursor] = useState<string>('');
    const [showOutput, setShowOutput] = useState<boolean>(false);

    useEffect(() => {
        try {
            if (!abiInput) {
                return;
            }
            const parsedAbi = JSON.parse(abiInput) as ABIInput[];
            setAbi(parsedAbi);

            const newEventsInfoMap: Record<string, any> = {};

            parsedAbi.forEach((func) => {
                if (func.type === 'function') {
                } else if (func.type === 'event') {
                    let functionTypes = [];
                    if (func.inputs) {
                        for (let input of func.inputs) {
                            if (input.type === 'tuple[]') {
                                functionTypes.push('(' + input.components?.map(c => c.type).join(',') + ')[]');
                            }
                            else if (input.type === 'tuple') {
                                functionTypes.push('(' + input.components?.map(c => c.type).join(',') + ')');
                            }
                            else {
                                functionTypes.push(input.type);
                            }
                        }
                    }
                    const signature = `${func.name}(${functionTypes.join(',')})`;
                    const hash = getKeccak256Hash(signature);
                    newEventsInfoMap[hash] = {
                        name: func.name,
                        hash: hash,
                        inputs: func.inputs
                    };
                }
            });

            setEventsInfoMap(newEventsInfoMap);
        } catch (e) {
            console.error('Invalid ABI JSON', e);
        }
    }, [abiInput]);

    const handleAbiInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAbiInput(event.target.value);
    };

    function convertCamelCaseToSentenceCase(camelCase: string) {
        return camelCase.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
    }

    function renderCellContent(cell: any) {
        if (cell.html) {
            return <div dangerouslySetInnerHTML={{ __html: cell.html }} />;
        } 
        if (cell.isHash) {
            return <CopyHash hash={cell.value} />;
        }
        return <div>{cell.value}</div>;
    }

    function jsonToTable(dataArray: any[]) {
        if (!dataArray?.length) {
            return null;
        }
        const headers = Object.keys(dataArray[0]);

        return (
            <table border={1}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{convertCamelCaseToSentenceCase(header)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataArray.map((row, index) => (
                        <tr key={index}>
                            {headers.map((header, i) => (
                                <td key={i}>{renderCellContent(row[header])}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    function getExportDataArray(dataArray: any[], flatten: boolean = false) {
        let outputDataArray: any[] = [];
        for (let i = 0; i < dataArray.length; i++) {
            const row = dataArray[i];
            const headers = Object.keys(row);
            let outputDataKvp: any = {};
            for (let j = 0; j < headers.length; j++) {
                const header = headers[j];
                let cellValue = row[header].value;
                outputDataKvp[header]= cellValue; 
            }
            if (flatten) {
                outputDataKvp = flattenObject(outputDataKvp);
            }
            outputDataArray.push(outputDataKvp);
        }
        return outputDataArray;
    }

    function exportToCSV(dataArray: any[]) {
        if (!dataArray || dataArray.length === 0) {
            return null;
        }
        let outputDataArray: any[] = getExportDataArray(dataArray, true);
        const headers = Object.keys(outputDataArray[0]);
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += headers.join(',') + '\r\n';
        outputDataArray.forEach((row) => {
            csvContent += headers.map((header) => row[header]).join(',') + '\r\n';
        });
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "event-log-decoder.csv");
        document.body.appendChild(link);
        link.click();
    }

    function exportToJSON(dataArray: any[]) {
        if (!dataArray || dataArray.length === 0) {
            return null;
        }
        let outputDataArray: any[] = getExportDataArray(dataArray);
        const json = JSON.stringify(outputDataArray, null, 4);
        const blob = new Blob([json], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "event-log-decoder.json");
        document.body.appendChild(link);
        link.click();
    }

    type FlattenedObject = { [key: string]: any };
    function flattenObject(obj: object): FlattenedObject {
        const result: FlattenedObject = {};

        function flatten(currentObj: any, parentKey: string = '') {
            Object.keys(currentObj).forEach(key => {
                const value = currentObj[key];
                const newKey = parentKey ? `${parentKey}.${key}` : key;

                if (value && typeof value === 'object' && !Array.isArray(value)) {
                    flatten(value, newKey);
                } else {
                    result[newKey] = value;
                }
            });
        }

        flatten(obj);
        return result;
    }

    function splitStringIntoChunks(str: string, chunkSize: number) {
        let chunks = [];
        for (let i = 0; i < str.length; i += chunkSize) {
            chunks.push(str.slice(i, i + chunkSize));
        }
        return chunks;
    }

    function jsonToLineBreaks(data: any, indent: number = 0): string {
        let result = "";
        for (const [key, value] of Object.entries(data)) {
            let indentValue = "&nbsp;&nbsp;".repeat(indent);
            let displayValue = `${key}: ${value} <br>`;

            if (typeof value === "object" && value !== null) {
                displayValue = `${key}: <br>`;
                displayValue += `${jsonToLineBreaks(value, indent + 1)} <br>`;
            }
            //   else if (typeof value === "string" && value.length > 10) {
            //     displayValue = value.substr(0, 10) + "..."; 
            //   }

            result += indentValue + displayValue;
        }

        return result;
    }

    async function handleAnkrAPICall() {
        let blockchainId = '';
        switch (selectedChainId) {
            case 1:
                blockchainId = 'eth';
                break;
            case 56:
                blockchainId = 'bsc';
                break;
            case 97:
                throw new Error('not supported!');
            case 43113:
                blockchainId = 'avalanche_fuji';
                break;
            case 43114:
                blockchainId = 'avalanche';
                break;
            case 137:
                blockchainId = 'polygon';
                break;
        }
        const url = `https://rpc.ankr.com/multichain/79258ce7f7ee046decc3b5292a24eb4bf7c910d7e39b691384c7ce0cfb839a01/?ankr_getLogs`;
        let topic0 = '0x' + selectedEventHash;
        const body = JSON.stringify({
            jsonrpc: '2.0',
            method: 'ankr_getLogs',
            params: [{
                address: [
                    contractAddressInput
                ],
                blockchain: blockchainId,
                fromBlock: 'earliest',
                toBlock: 'latest',
                topics: [
                    [
                        topic0
                    ]
                ],
                pageSize: 1000
            }],
            id: 1
        });

        const response = await fetch(url, {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const result = await response.json();
        const fromBlockNumber = result.result.logs[result.result.logs.length - 1].blockNumber;
        const toBlockNumber = result.result.logs[0].blockNumber;
        return {
            logs: result.result.logs
        }
    }

    async function handleMoralisAPICall() {
        let topic0 = '0x' + selectedEventHash;
        let blockchainId = '0x' + Number(selectedChainId).toString(16);
        const url = `https://deep-index.moralis.io/api/v2.2/${contractAddressInput}/logs?chain=${blockchainId}&topic0=${topic0}`
        const APIKey = apiKeyInput;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': APIKey
            }
        });
        const result = await response.json();
        return {
            logs: result.result.map((v: any) => {
                return {
                    blockTimestamp: v.block_timestamp,
                    blockNumber: v.block_number,
                    transactionHash: v.transaction_hash,
                    blockHash: v.block_hash,
                    data: v.data,
                    topics: [v.topic0, v.topic1, v.topic2, v.topic3].filter((v: string) => v)
                }
            }),
            cursor: result.cursor
        }
    }

    function formatDateToCustomFormat(isoDate: string): string {
        const date = new Date(isoDate);
        const addLeadingZero = (num: number): string => {
            return num < 10 ? '0' + num : num.toString();
        };

        const yyyy = date.getFullYear();
        const MM = addLeadingZero(date.getMonth() + 1); 
        const dd = addLeadingZero(date.getDate());
        const HH = addLeadingZero(date.getHours());
        const mm = addLeadingZero(date.getMinutes());
        const ss = addLeadingZero(date.getSeconds());

        return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
    }

    async function handleQueryButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        let result;
        if (selectedAPIProvider === 'ankr') {
            result = await handleAnkrAPICall();
        }
        else if (selectedAPIProvider === 'moralis') {
            result = await handleMoralisAPICall();
            setPrevMoralisAPICursor(nextMoralisAPICursor);
            setNextMoralisAPICursor(result.cursor);
        }
        else {
            throw new Error('not supported!');
        }
        const hexToBigInt = (hex: string) => BigInt(hex);
        const bigIntToNumber = (bi: bigint) => Number(bi);
        const hexToDecimalString = (hex: string) => hexToBigInt(hex).toString();
        const decodeEventData = (data: string, type: string) => {
            let output: any;
            if (type === 'address') {
                output = '0x' + data.replace('000000000000000000000000', '');
            }
            else if (type.startsWith('uint')) {
                output = hexToDecimalString("0x" + data);
            }
            else if (type.startsWith('int')) {
                output = hexToDecimalString("0x" + data);
            }
            else if (type === 'bytes32') {
                output = '0x' + data;
            }
            else if (type === 'bool') {
                output = (hexToBigInt("0x" + data) === 1n).toString();
            }
            else if (type === 'string') {
                output = bytes32ToString('0x' + data);
            }
            return output;
        }

        const showDate = result.logs[0]?.blockTimestamp ? true : false;
        const outputData = result.logs.map((log: any) => {
            const { topics, data, blockNumber, transactionHash, blockHash, blockTimestamp } = log;
            let outputObj: Record<string, any> = {};
            const event = eventsInfoMap[topics[0].replace('0x', '')];
            const nonIndexedData = splitStringIntoChunks(data.replace('0x', ''), 64);
            const indexedData = topics.slice(1).map((v: string) => v.replace('0x', ''));
            let nonIndexedDataCount = 0;
            let indexedDataCount = 0;
            for (let input of event.inputs) {
                if (input.indexed) {
                    if (input.type === 'tuple') {
                        outputObj[input.name] = {};
                        for (let component of input.components) {
                            outputObj[input.name][component.name] = decodeEventData(indexedData[indexedDataCount++], component.type);
                        }
                    }
                    else {
                        outputObj[input.name] = decodeEventData(indexedData[indexedDataCount++], input.type);
                    }
                }
                else {
                    if (input.type === 'tuple') {
                        outputObj[input.name] = {};
                        for (let component of input.components) {
                            outputObj[input.name][component.name] = decodeEventData(nonIndexedData[nonIndexedDataCount++], component.type);
                        }
                    }
                    else {
                        outputObj[input.name] = decodeEventData(nonIndexedData[nonIndexedDataCount++], input.type);
                    }
                }
            }
            let item: any = {};
            if (showDate) {
                item.date = {
                    value: blockTimestamp ? formatDateToCustomFormat(blockTimestamp) : ''
                };
            }
            item = {
                ...item,
                blockNumber: {
                    value: hexToDecimalString(blockNumber),
                },
                // eventName: event.name,
                data: {
                    html: jsonToLineBreaks(outputObj),
                    value: outputObj
                },
                transactionHash: {
                    value: transactionHash,
                    isHash: true
                },
                blockHash: {
                    value: blockHash,
                    isHash: true
                }
            };
            return item;
        }).sort((a: any, b: any) => b.blockNumber - a.blockNumber);
        setResultsCount(outputData.length);
        setOutputData(outputData);
        setShowOutput(true);
    }

    const handleContractAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContractAddressInput(event.target.value);
    };

    const handleChainSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedChainId(parseInt(event.target.value));
    };

    const handleAPIProviderSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedAPIProvider(event.target.value);
    };

    const handleEventSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedEventHash(event.target.value);
    }

    const handleAPIKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAPIKeyInput(event.target.value);
    };

    const handleCommonContractsSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCommonContracts(event.target.value);
        if (event.target.value === 'UniswapV3Pool') {
            setAbiInput(JSON.stringify(uniswapV3PoolABI, null, 4));
        }
    }

    const handleUseCustomAbiCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUseCustomAbiCheckbox(event.target.checked);
    }

    const isEthereumAddress = (address: string) => {
        return /^0x[a-fA-F0-9]{40}$/.test(address);
    }

    const isQueryButtonDisabled = () => {
        if (!contractAddressInput || !isEthereumAddress(contractAddressInput)) {
            return true;
        }
        if (!abiInput) {
            return true;
        }
        if (selectedAPIProvider === 'moralis' && !apiKeyInput) {
            return true;
        }
        return false;
    }

    return (
        <div style={containerStyle}>
            <h2 style={headerStyle}>Event Log Decoder</h2>
            <div id="container">
                <form>
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="common-contracts-select" style={{ width: '15%' }}>Common Contracts:</label>
                        <select id="common-contracts-select" name="common-contracts-select" value={selectedCommonContracts} onChange={handleCommonContractsSelectChange}>
                            <option value="UniswapV3Pool">UniswapV3Pool</option>
                        </select>
                        <input 
                            type="checkbox" 
                            id="use-custom-abi-checkbox" 
                            name="use-custom-abi-checkbox" 
                            style={{ marginLeft: '1rem' }} 
                            checked={useCustomAbiCheckbox} 
                            onChange={handleUseCustomAbiCheckboxChange}
                        ></input>
                        <label htmlFor="use-custom-abi-checkbox" style={{ marginLeft: '0.5rem' }}>Use Custom ABI</label>
                    </div>
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }} >
                        <label htmlFor="abi-input" style={{ width: '15%' }}>ABI:</label>
                        <textarea id="abi-input" value={abiInput} rows={10} style={{ width: '85%' }} onChange={handleAbiInputChange} disabled={!useCustomAbiCheckbox}></textarea>
                    </div>
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="chain-select" style={{ width: '15%' }}>Blockchain Network:</label>
                        <select id="chain-select" name="chain-select" value={selectedChainId} onChange={handleChainSelectChange}>
                            {Networks.map((network) => (
                                <option value={network.chainId}>{network.name}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="contract-address" style={{ width: '15%' }}>Contract Address:</label>
                        <input type="text" id="contract-address" name="contract-address" style={{ minWidth: '350px' }} value={contractAddressInput} onChange={handleContractAddressChange}></input>
                    </div>
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="api-provider-select" style={{ width: '15%' }}>API Provider:</label>
                        <select id="api-provider-select" name="api-provider-select" value={selectedAPIProvider} onChange={handleAPIProviderSelectChange}>
                            <option value="ankr">Ankr</option>
                            <option value="moralis">Moralis</option>
                        </select>
                    </div>   
                    {selectedAPIProvider === 'moralis' && (
                        <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                            <label htmlFor="api-key" style={{ width: '15%' }}>API Key:</label>
                            <input type="text" id="api-key" name="api-key" style={{ minWidth: '550px' }} value={apiKeyInput} onChange={handleAPIKeyChange}></input>
                        </div>      
                    )}                     
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="event-select" style={{ width: '15%' }}>Event:</label>
                        <select id="event-select" name="event-select" value={selectedEventHash} onChange={handleEventSelectChange}>
                            {Object.keys(eventsInfoMap).map((eventKey) => (
                                <option value={eventKey}>{eventsInfoMap[eventKey].name}</option>
                            ))}
                        </select>
                    </div>         
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
                        <button type="button" className="query-button" onClick={handleQueryButtonClick} disabled={isQueryButtonDisabled()}>Query</button>
                    </div>
                </form>
                {showOutput && (
                    <div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '1rem 0' }}>
                            <h3>Output</h3>
                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                                <button 
                                    type="button" 
                                    className="export-button" 
                                    onClick={() => exportToCSV(outputData)}>Export CSV
                                </button>
                                <button 
                                    type="button" 
                                    className="export-button" 
                                    onClick={() => exportToJSON(outputData)}>Export JSON
                                </button>
                            </div>
                        </div>
                        <div>Results: {resultsCount}</div>
                        <div style={{ overflow: 'auto' }}>
                            {jsonToTable(outputData)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EventLogDecoder;