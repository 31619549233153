import React, { useState, useEffect } from 'react';
import './index.css';
import { containerStyle, headerStyle } from "../../styles/common";
import { decodeOutputData, encodeInputData, ethCall, getKeccak256Hash } from '../../utils/ethRawCipher';
import uniswapV3PoolABI from '../../abi/uniswapV3PoolABI.json';

interface ABIInput {
    type: string;
    name: string;
    inputs?: { name: string; type: string }[];
}

const Networks = [
    {
        name: 'Ethereum Mainnet',
        chainId: 1
    },
    {
        name: 'Binance Smart Chain',
        chainId: 56
    },
    {
        name: 'Binance Smart Chain Testnet',
        chainId: 97
    },
    {
        name: 'Avalanche Fuji Testnet',
        chainId: 43113
    },
    {
        name: 'Avalanche Mainnet',
        chainId: 43114
    },
    {
        name: 'Polygon',
        chainId: 137
    }
];

function ContractStateInspector() {
    const [selectedCommonContracts, setSelectedCommonContracts] = useState<string>('UniswapV3Pool');
    const [useCustomAbiCheckbox, setUseCustomAbiCheckbox] = useState<boolean>(false);
    const [abiInput, setAbiInput] = useState<string>(JSON.stringify(uniswapV3PoolABI, null, 4));
    const [abi, setAbi] = useState<ABIInput[]>([]);
    const [contractAddressInput, setContractAddressInput] = useState('');
    const [eventsInfoMap, setEventsInfoMap] = useState<Record<string, any>>({});
    const [selectedFunctionName, setSelectedFunctionName] = useState<string>('');
    const [functionSelect, setFunctionSelect] = useState<string[]>([]);
    const [inputFields, setInputFields] = useState<{ name: string; type: string }[]>([]);
    const [inputValues, setInputValues] = useState<Record<string, string>>({});
    const [selectedChainId, setSelectedChainId] = useState<number>(137);
    const [outputData, setOutputData] = useState<Record<string, any>>({});
    const [showOutput, setShowOutput] = useState<boolean>(false);

    useEffect(() => {
        try {
            if (!abiInput) {
                return;
            }
            const parsedAbi = JSON.parse(abiInput) as ABIInput[];
            setAbi(parsedAbi);

            const newFunctionSelect: string[] = [];
            const newEventsInfoMap: Record<string, any> = {};

            parsedAbi.forEach((func) => {
                if (func.type === 'function') {
                    newFunctionSelect.push(func.name);
                } else if (func.type === 'event') {
                    const signature = func.name + '(' + func.inputs?.map((v: any) => v.type).join(',') + ')'
                    const hash = getKeccak256Hash(signature);
                    newEventsInfoMap[hash] = {
                        name: func.name,
                        hash: hash,
                        inputs: func.inputs
                    };
                }
            });

            setFunctionSelect(newFunctionSelect);
            setEventsInfoMap(newEventsInfoMap);
        } catch (e) {
            console.error('Invalid ABI JSON', e);
        }
    }, [abiInput]);

    const handleAbiInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAbiInput(event.target.value);
    };

    const handleFunctionSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const functionName = event.target.value;
        setSelectedFunctionName(functionName);

        const selectedFunction = abi.find((func) => func.name === functionName && func.type === 'function');
        if (selectedFunction && selectedFunction.inputs) {
            setInputFields(selectedFunction.inputs);
        }
    };


    function jsonToTable(data: Record<string, any>) {
        return (
            <table border={1}>
                <thead>
                    <tr>
                        <th>Property</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(data).map((key, index) => (
                        <tr key={index}>
                            <td>{key}</td>
                            <td>{data[key]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    async function handleQueryButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        let functionParams = [];
        for (let i = 0; i < inputFields.length; i++) {
            const inputValue = inputValues[inputFields[i].name];
            functionParams.push(inputValue);
        }
        const inputs = abi.find(v => v.name === selectedFunctionName)?.inputs || [];
        let types = inputs.map(v => v.type).join(',');
        let functionHash = getKeccak256Hash(`${selectedFunctionName}(${types})`);
        let data = '0x' + functionHash.substr(0, 8) + encodeInputData(inputs, functionParams);
        console.log('data', data)
        let chainRpcUrlMap: Record<number, string> = {
            1: 'https://rpc.ankr.com/eth',
            56: 'https://rpc.ankr.com/bsc',
            97: "https://rpc.ankr.com/bsc_testnet_chapel",
            137: "https://rpc.ankr.com/polygon",
            43113: 'https://api.avax-test.network/ext/bc/C/rpc',
            43114: 'https://api.avax.network/ext/bc/C/rpc'
        }
        let chainRpcUrl = chainRpcUrlMap[selectedChainId];
        const outputRawData = await ethCall(chainRpcUrl, contractAddressInput, data);
        console.log('outputRawData', outputRawData);
        const outputData = decodeOutputData(abi.find(v => v.name === selectedFunctionName), outputRawData);
        console.log('outputData', outputData);
        setOutputData(outputData);
        setShowOutput(true);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        const value = event.target.value;
        setInputValues({
            ...inputValues,
            [fieldName]: value,
        });
    };

    const handleContractAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContractAddressInput(event.target.value);
    };

    const handleChainSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedChainId(parseInt(event.target.value));
    };

    const handleCommonContractsSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCommonContracts(event.target.value);
        if (event.target.value === 'UniswapV3Pool') {
            setAbiInput(JSON.stringify(uniswapV3PoolABI, null, 4));
        }
    }

    const handleUseCustomAbiCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUseCustomAbiCheckbox(event.target.checked);
    }    

    const isEthereumAddress = (address: string) => {
        return /^0x[a-fA-F0-9]{40}$/.test(address);
    }

    const isQueryButtonDisabled = () => {
        return !contractAddressInput || !isEthereumAddress(contractAddressInput) || !abiInput;
    }

    return (
        <div style={containerStyle}>
            <h2 style={headerStyle}>Contract State Inspector</h2>
            <div id="container">
                <form>
                <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="common-contracts-select" style={{ width: '15%' }}>Common Contracts:</label>
                        <select id="common-contracts-select" name="common-contracts-select" value={selectedCommonContracts} onChange={handleCommonContractsSelectChange}>
                            <option value="UniswapV3Pool">UniswapV3Pool</option>
                        </select>
                        <input 
                            type="checkbox" 
                            id="use-custom-abi-checkbox" 
                            name="use-custom-abi-checkbox" 
                            style={{ marginLeft: '1rem' }} 
                            checked={useCustomAbiCheckbox} 
                            onChange={handleUseCustomAbiCheckboxChange}
                        ></input>
                        <label htmlFor="use-custom-abi-checkbox" style={{ marginLeft: '0.5rem' }}>Use Custom ABI</label>
                    </div>
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="abi-input" style={{ width: '15%' }}>ABI:</label>
                        <textarea id="abi-input" value={abiInput} rows={10} style={{ width: '85%' }} onChange={handleAbiInputChange} disabled={!useCustomAbiCheckbox}></textarea>
                    </div>
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="chain-select" style={{ width: '15%' }}>Blockchain Network:</label>
                        <select id="chain-select" name="chain-select" value={selectedChainId} onChange={handleChainSelectChange}>
                            {Networks.map((network) => (
                                <option value={network.chainId}>{network.name}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="contract-address" style={{ width: '15%' }}>Contract Address:</label>
                        <input type="text" id="contract-address" name="contract-address" value={contractAddressInput} onChange={handleContractAddressChange}></input>
                    </div>
                    <div style={{ display: 'flex', width: '100%', padding: '1rem 0' }}>
                        <label htmlFor="function-select" style={{ width: '15%' }}>Function:</label>
                        <select id="function-select" name="function-select" value={selectedFunctionName} onChange={handleFunctionSelectChange}>
                            {functionSelect.map((option) => (
                                <option>{option}</option>
                            ))}
                        </select>
                    </div>
                    <div id="input-container">
                        {inputFields.map((inputField) => (
                            <div key={inputField.name}>
                                <label>{inputField.name} ({inputField.type}):</label>
                                <input
                                    type="text"
                                    id={inputField.name}
                                    name={inputField.name}
                                    value={inputValues[inputField.name] || ''}
                                    onChange={(e) => handleInputChange(e, inputField.name)}
                                />
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
                        <button type="button" className="query-button" onClick={handleQueryButtonClick} disabled={isQueryButtonDisabled()}>Query</button>
                    </div>
                </form>
                {showOutput && (
                    <div>
                        <h3>Output</h3>
                        <div>
                            {jsonToTable(outputData)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ContractStateInspector;