import React, { useState } from 'react';

const truncateHash = (hash: string, length: number = 10): string => {
    return `${hash.substring(0, length)}...`;
};

const CopyHash = ({ hash }: { hash: string }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(hash);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset icon after 2 seconds
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{truncateHash(hash)}</span>
            {isCopied ? (
                <i className="fa fa-check" style={{ cursor: 'pointer', marginLeft: '5px' }}></i>
            ) : (
                <i className="fa fa-copy" onClick={handleCopy} style={{ cursor: 'pointer', marginLeft: '5px' }}></i>
            )}
        </div>
    );
};

export default CopyHash;
